.App {
  text-align: center;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes fa-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.35;
  }
}

.fa-blink {
  -webkit-animation: fa-blink 1s linear infinite;
  -moz-animation: fa-blink 1s linear infinite;
  -o-animation: fa-blink 1s linear infinite;
  animation: fa-blink 1s linear infinite;
  color: rgb(255, 116, 23);
}

.fa-blink:hover {
  text-decoration: none;
  color: white;
  font-size: 21px;
  cursor: pointer;
}

#mailcolor {
  color: rgb(255, 116, 23);
}

#mailcolor:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

#nav-iconn {
  box-shadow: 0 6px 5px -5px rgb(172, 77, 14);
}

#navbar {
  box-shadow: 1px 1px 2px white;
  background-color: black;
}

#navbar i {
  color: rgb(255, 116, 23);
}

#navbar i:hover {
  color: white;
}

#nav-collapse {
  color: rgb(255, 116, 23);
}

#nav-collapse:hover {
  color: white;
}

.card {
  width: 100%;
  height: 90%;
  box-shadow: 2px 2px 6px gray;
}

.card:hover {
  box-shadow: 4px 5px 4px rgb(156, 156, 156);
}
.card:hover .ctitle {
  transform: translateY(-40px);
}

.card img {
  margin: 7% 0 7% 0;
  padding: 4%;
  width: 80%;
  height: 60%;
}

.ctitle {
  transition: transform 0.5s;
  z-index: 1;
  color: black;
  font-weight: bold;
  background-color: rgba(255, 116, 23, 0.788);
}

.card .cardtext {
  visibility: hidden;
  color: black;
  padding: 20px 3px;
  transition: transform 0.5s;
  z-index: 1;
  font-size: 20px;
  background-color: rgba(247, 244, 242, 0.329);
}
.card:hover .cardtext {
  visibility: visible;
  transform: translateY(-40px);
}

.ctext {
  margin-top: 110px;
}

.zoom {
  transition: transform 0.2s;
}
.zoom:hover {
  -ms-transform: scale(1.02);
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

#picCol {
  height: 100vh;
  margin-top: 4px;
  padding-top: 3vh;
  padding-bottom: 3vh;
}
#picCol img {
  border: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 425px) {
  #name {
    font-size: 25px;
  }
  .col {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .card {
    height: 22vh;
    margin-bottom: 10%;
  }
  .ctext {
    margin-top: 70px;
  }
  .ctitle {
    font-size: 20px;
  }
  #cardtext {
    font-size: 12px;
  }
  #nav-icon {
    padding: 0%;
  }
  #nav-collapse {
    justify-content: center;
  }
}

@media screen and (min-width: 425px) {
  #desc {
    padding-left: 4%;
  }

  .col-6 {
    padding: 30px;
  }
  #nav-collapse {
    justify-content: flex-end;
  }
}
